import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ENDPOINTS } from "@/utils/constants/apiConstants";

import { baseAPI } from "../services/fetchers";

const requestBodyListDefault = {
  page: 1,
  page_size: 10,
  sort_field: undefined,
  search_text: undefined,
  is_pagination: true,
  sort_orientation: "1", // 1 = Descending,
};

export const setKycStatusAsync = createAsyncThunk(
  "users/setKycStatusAsync",
  async (body) => {
    try {
      const end_ = ENDPOINTS.USER_ADMIN_SET_STATUS.replace(
        "{userId}",
        body.user_id
      );
      const response = await baseAPI({
        endPoint: end_,
        body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const setUserStatusAsync = createAsyncThunk(
  "users/setUserStatusAsync",
  async (body) => {
    try {
      const end_ = ENDPOINTS.USER_ADMIN_SET_USER_STATUS.replace(
        "{userId}",
        body.user_id
      );
      const response = await baseAPI({
        endPoint: end_,
        body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const setAdminUserConfigAsync = createAsyncThunk(
  "users/setAdminUserConfigAsync",
  async (body) => {
    try {
      const end_ = ENDPOINTS.USER_ADMIN_CONFIG_SET;
      const response = await baseAPI({
        endPoint: end_,
        body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const addRoleAsync = createAsyncThunk(
  "users/addRoleAsync",
  async (body) => {
    try {
      const end_ = ENDPOINTS.USER_ADMIN_ADD_ROLE;
      const response = await baseAPI({
        endPoint: end_,
        body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const editRoleAsync = createAsyncThunk(
  "users/editRoleAsync",
  async (body) => {
    try {
      const end_ = ENDPOINTS.USER_ADMIN_EDIT_ROLE;
      const response = await baseAPI({
        endPoint: end_,
        body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const deleteRoleAsync = createAsyncThunk(
  "users/deleteRoleAsync",
  async (roleId) => {
    try {
      const end_ = ENDPOINTS.USER_ADMIN_DELETE_ROLE.replace("{roleId}", roleId);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getRolesAsync = createAsyncThunk(
  "users/getRolesAsync",
  async (body) => {
    try {
      const end_ = ENDPOINTS.USER_ADMIN_ROLES;
      const response = await baseAPI({
        endPoint: end_,
        body,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getUserRoleAsync = createAsyncThunk(
  "users/getUserRoleAsync",
  async (uId) => {
    try {
      const end_ = ENDPOINTS.USER_ADMIN_GET_USER_TOLE.replace("{userId}", uId);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const assignRoleAsync = createAsyncThunk(
  "users/assignRoleAsync",
  async (data) => {
    const { userId, roleId } = data;
    try {
      const end_ = ENDPOINTS.USER_ADMIN_ASSIGN_USER_ROLE.replace(
        "{userId}",
        userId
      ).replace("{roleId}", roleId);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const unAssignRoleAsync = createAsyncThunk(
  "users/unAssignRoleAsync",
  async (data) => {
    const { userId, roleId } = data;
    try {
      const end_ = ENDPOINTS.USER_ADMIN_UN_ASSIGN_USER_ROLE.replace(
        "{userId}",
        userId
      ).replace("{roleId}", roleId);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const updatePrivilegesUserAsync = createAsyncThunk(
  "users/updatePrivilegesUserAsync",
  async (data) => {
    const { userId, privileges } = data;
    try {
      const end_ = ENDPOINTS.USER_ADMIN_UPDATE_PRIVILEGES.replace(
        "{userId}",
        userId
      );
      const response = await baseAPI({
        endPoint: end_,
        body: privileges,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const updateUserPhoneNumberAsync = createAsyncThunk(
  "account/mobile/change_publisher",
  async (data) => {
    try {
      const end_ = ENDPOINTS.USER_ADMIN_UPDATE_PHONENUMBER;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getPrivilegesUserAsync = createAsyncThunk(
  "users/getPrivilegesUserAsync",
  async (userId) => {
    try {
      const end_ = ENDPOINTS.USER_ADMIN_USER_GET_PRIVILEGES.replace(
        "{userId}",
        userId
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const addAdminAsync = createAsyncThunk(
  "users/addAdminAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.USER_ADMIN_ADD;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const editAdminAsync = createAsyncThunk(
  "users/editAdminAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.USER_ADMIN_EDIT.replace("{userId}", data?.userId);
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });
      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getAdminListAsync = createAsyncThunk(
  "users/getAdminListAsync",
  async (data) => {
    try {
      const body_ = Object.assign({}, requestBodyListDefault, data);
      const end_ = ENDPOINTS.USER_ADMIN_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);
export const changeAdminStatusAsync = createAsyncThunk(
  "users/changeAdminStatus",
  async (body) => {
    try {
      const end_ = ENDPOINTS.USER_ADMIN_SET_ADMIN_STATUS.replace(
        "{userId}",
        body.id
      ).replace("{isActive}", body.status);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const adminChangePasswordAsync = createAsyncThunk(
  "account/adminChangePasswordAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.USER_ADMIN_CHANGE_PASSWORD;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const adminGetDetailAsync = createAsyncThunk(
  "account/adminGetDetailAsync",
  async (userId) => {
    try {
      const end_ = ENDPOINTS.USER_ADMIN_GET_USER_INFO.replace(
        "{userId}",
        userId
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const adminUserSlice = createSlice({
  name: "adminUser",
  initialState: {
    loading: false,
    error: null,

    rolesLoading: false,
    roles: false,
    rolesError: null,

    roleLoading: false,
    roleError: false,

    privilegesLoading: false,

    adminsLoading: false,
    admins: null,
    adminsError: null,

    detailsError: null,
    detailsLoading: false,
    details: null,

    adminsAddLoading: false,
  },
  reducers: {
    clearState: (state) => {
      state.admins = false;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setKycStatusAsync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(setKycStatusAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(setKycStatusAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(setUserStatusAsync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(setUserStatusAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(setUserStatusAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getRolesAsync.pending, (state, action) => {
      state.rolesLoading = true;
      state.error = null;
    });
    builder.addCase(getRolesAsync.rejected, (state, action) => {
      state.rolesLoading = false;
      state.error = action.error;
    });
    builder.addCase(getRolesAsync.fulfilled, (state, action) => {
      state.rolesLoading = false;
      state.error = null;
      if (action.payload.success) {
        state.roles = action.payload;
      }
    });

    builder.addCase(addRoleAsync.pending, (state, action) => {
      state.roleLoading = true;
      state.roleError = null;
    });
    builder.addCase(editRoleAsync.pending, (state, action) => {
      state.roleLoading = true;
      state.roleError = null;
    });

    builder.addCase(getPrivilegesUserAsync.pending, (state, action) => {
      state.privilegesLoading = true;
    });

    builder.addCase(addAdminAsync.pending, (state, action) => {
      state.adminsAddLoading = true;
      state.adminsError = null;
    });
    builder.addCase(addAdminAsync.rejected, (state, action) => {
      state.adminsAddLoading = false;
      state.adminsError = action.error;
    });
    builder.addCase(addAdminAsync.fulfilled, (state, action) => {
      // if (action.payload.success) {
      //   state.admins = action.payload;
      // }
      state.adminsAddLoading = false;
      state.error = null;
    });

    builder.addCase(getAdminListAsync.pending, (state, action) => {
      state.adminsLoading = true;
      state.adminsError = null;
    });
    builder.addCase(getAdminListAsync.rejected, (state, action) => {
      state.adminsLoading = false;
      state.adminsError = action.error;
    });
    builder.addCase(getAdminListAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.admins = action.payload;
      }
      state.adminsLoading = false;
      state.adminsError = null;
    });
    builder.addCase(changeAdminStatusAsync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(changeAdminStatusAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(changeAdminStatusAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(adminChangePasswordAsync.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(adminChangePasswordAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(adminChangePasswordAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(adminGetDetailAsync.pending, (state, action) => {
      state.detailsLoading = true;
      state.detailsError = null;
    });
    builder.addCase(adminGetDetailAsync.rejected, (state, action) => {
      state.detailsLoading = false;
      state.detailsError = action.error;
    });
    builder.addCase(adminGetDetailAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.details = action.payload;
      }
      state.detailsLoading = false;
      state.detailsError = null;
    });

    builder.addDefaultCase((state, action) => {
      if (action.type?.includes("users/getPrivilegesUserAsync")) {
        state.privilegesLoading = false;
      } else {
        state.roleLoading = false;
        state.roleError = null;
      }
    });
  },
});

export const setKycStatus = (data) => async (dispatch) => {
  return dispatch(setKycStatusAsync(data));
};

export const setUserStatus = (data) => async (dispatch) => {
  return dispatch(setUserStatusAsync(data));
};

export const setAdminUserConfig = (data) => async (dispatch) => {
  return dispatch(setAdminUserConfigAsync(data));
};

export const getRoles = (data) => async (dispatch) => {
  return dispatch(getRolesAsync(data));
};

export const addRole = (data) => async (dispatch) => {
  return dispatch(addRoleAsync(data));
};

export const editRole = (data) => async (dispatch) => {
  return dispatch(editRoleAsync(data));
};

export const deleteRole = (data) => async (dispatch) => {
  return dispatch(deleteRoleAsync(data));
};

export const getUserRole = (data) => async (dispatch) => {
  return dispatch(getUserRoleAsync(data));
};

export const assignRole = (data) => async (dispatch) => {
  return dispatch(assignRoleAsync(data));
};

export const unAssignRole = (data) => async (dispatch) => {
  return dispatch(unAssignRoleAsync(data));
};

export const updatePrivilegesUser = (data) => async (dispatch) => {
  return dispatch(updatePrivilegesUserAsync(data));
};
export const updatePhoneNumberUser = (data) => async (dispatch) => {
  return dispatch(updateUserPhoneNumberAsync(data));
};

export const getPrivilegesUser = (data) => async (dispatch) => {
  return dispatch(getPrivilegesUserAsync(data));
};

export const getAdminList = (data) => async (dispatch) => {
  return dispatch(getAdminListAsync(data));
};

export const addAdmin = (data) => async (dispatch) => {
  return dispatch(addAdminAsync(data));
};

export const editAdmin = (data) => async (dispatch) => {
  return dispatch(editAdminAsync(data));
};

export const changeAdminStatus = (data) => async (dispatch) => {
  return dispatch(changeAdminStatusAsync(data));
};

export const adminChangePassword = (data) => async (dispatch) => {
  return dispatch(adminChangePasswordAsync(data));
};

export const adminGetDetail = (data) => async (dispatch) => {
  return dispatch(adminGetDetailAsync(data));
};

export default adminUserSlice.reducer;

export const { clearState } = adminUserSlice.actions;
